<template>
  <div v-if="this.cabecario[0]">

    <div class="row">
      <div class="col-12" v-if="modo == 1">
        <button style="float:right;border: 1px solid #b5b5b5;" @click="modoEdicao()" class="btn" > Modo Edição</button>
      </div>
    </div>

    <div class="row" v-if="modo == 1">

        <div :class="this.config.brasao == '100' ? 'col-12' : 'col-3'">
          <div v-if="this.cabecario[0].logo"  style=" text-align: center;" >
            <img :src="base_url+'/storage/'+this.cabecario[0].logo"  :style="this.config.brasao == '100' ? 'width: 24%;height: 179px;' : 'width:100%;' " />
          </div>
        </div>

        <div :class="this.config.texto1 == '100' ? 'col-12' : 'col-9'" style="align-items: center;display: flex;justify-content: center;">
          <p :style="this.config.brasao == '100' ? '' : '' " style="text-align: center;" v-if="this.confirme == 1" v-html="this.texto_logo" ></p>
          <p :style="this.config.brasao == '100' ? '' : '' " style="text-align: center;" v-else  v-html="this.cabecario[0].texto_logo"></p>
        </div>

        <div class="col-12" style="text-align: center;margin-top:30px;" v-html="titulo"></div>

        <div class="col-12" style="margin-top:30px;" v-if="local == 1">
          <div v-if="this.confirme == 0">
            <textoSegmento :cabecario_id="this.cabecario_id" :segmento_id="this.segmento_id" :integral="this.integral"  :editar="0"/>
          </div>
          <div v-else v-html="this.texto_segmento"></div>
        </div>

        <div class="col-12" style="margin-top:30px;" v-if="local == 0">
          <div v-if="this.text_seg && this.confirme == 0">
            <textoSegmento :cabecario_id="this.cabecario_id"
              :segmento_id="this.text_seg.segmento_id"
              :integral="this.text_seg.integral"
              :key="this.text_seg"
              :editar="0"
              />
          </div>
          <div v-else v-html="this.texto_segmento"></div>
        </div>

        <div class="col-3" style="margin-top:30px;">
          <label>Selecione a Segmento</label>
          <select class="p-inputtext p-component" v-model="text_seg"
              style="appearance: revert !important;width: 100% !important;"  @change="this.confirme = 0;">

              <option value="0" disabled selected> -- Escolha um Segmento -- </option>
              <option v-for="n in this.texto_cabecario" :key="n" :value="n" >
                {{n.segmento_nome}} - <b v-if="n.integral == 1">Integral</b><b v-else>Parcial</b>
              </option>
          </select>
        </div>

    </div>

    <div class="row" v-if="modo == 2">
      <div class="col-12">
        <button style="float:right;border: 1px solid #b5b5b5;" @click="modoEdicao()" class="btn" > Modo Edição</button>
        <button style="float:right;border: 1px solid #b5b5b5;margin-right:5px;" @click="confirmar()" class="btn btn-success"  > Confirmar Mudança</button>
      </div>

     <!--Modo edição -->
      <div :class="this.config.brasao == '100' ? 'col-12' : 'col-3'">
        <div v-if="this.cabecario[0].logo"  style=" text-align: center;" >
          <img :src="base_url+'/storage/'+this.cabecario[0].logo"  :style="this.config.brasao == '100' ? 'width: 24%;height: 179px;' : 'width:100%;' " />
        </div>
      </div>

      <div :class="this.config.texto1 == '100' ? 'col-12' : 'col-9'" style="align-items: center;display: flex;justify-content: center;">
        <textarea  rows="5" cols="30" v-model="this.texto_logo" class="p-inputtext" style="width: 100%;">
        </textarea>
      </div>

      <div class="col-12" style="text-align: center;margin-top:30px;">
        <input type="text" placeholder="Digite o Titulo" v-model="titulo" class="p-inputtext" style="width:100%" />
      </div>

      <div class="col-12" style="margin-top:30px;" v-if="local == 1">
        <textoSegmento :cabecario_id="this.cabecario_id" :segmento_id="this.segmento_id" :integral="this.integral"
           :editar="1" @textoSegmentoAtual="textoSegmentoAtual"  />
      </div>

      <div class="col-12" style="margin-top:30px;" v-if="local == 0">
        <div v-if="this.text_seg">
           <textoSegmento :cabecario_id="this.cabecario_id"
             :segmento_id="this.text_seg.segmento_id"
             :integral="this.text_seg.integral"
             :key="this.text_seg"
             :editar="1"
             @textoSegmentoAtual="textoSegmentoAtual"
            />
        </div>
      </div>

    </div>

    <div style="background-color: #f5e6d3;padding: 14px;margin-top:30px;" v-if="modo == 1">
       <h6>Modelos de Exibição</h6>
       <div class="col-md-12" style="justify-content: space-around;display: flex;font-size: 11px;flex-wrap: wrap;width: 33%;align-items: center;">
          <input type="radio" class="form-check-input" id="radio1" v-model="modelo" name="optradio" value="padrao" @change="mudarPadrao()" checked>Padrão
          <label class="form-check-label" for="radio1">
              <img src="/img/tema01.png" style="width: 115px;">
          </label>

          <input type="radio" class="form-check-input" id="radio2" v-model="modelo" name="optradio" value="versao2" @change="mudarPadrao()">Versão 2
          <label class="form-check-label" for="radio2">
              <img src="/img/tema02.png" style="width: 115px;">
          </label>
       </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import textoSegmento from "./segmentoTexto.vue";

export default defineComponent ({
  props:{
    cabecario_id: 0,
    local:'',
    segmento_id:'',
    integral:''
  },
  components:{
    textoSegmento
  },
  data(){
    return{
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      cabecario:[],

      titulo:'Titulo do Relatório',
      texto_logo:'',
      texto_segmento:'',

      config:[],
      modelo:'padrao',
      modo: 1,
      tipo:true,
      texto_cabecario:[],
      text_seg:[],
      confirme:0,
    }
  },
  async beforeMount() {
    let newl={
      brasao: '100',
      texto1: '100',
    }
    this.config = newl
    this.buscarCabecario();

    if(this.local == 0){
      this.textoSegmento();
    }

    if(this.confirme == 1){
      this.texto_logo = sessionStorage.getItem("texto_logo_cb")
      this.titulo = sessionStorage.getItem("titulo_cb")
      this.texto_segmento = sessionStorage.getItem("texto_segmento_cb")
    }
  },
  methods: {
    buscarCabecario(){console.log(this.confirme)
      if(this.confirme == 0){
        axios.get('/relatorio-config-cabecario/'+this.cabecario_id)
            .then((resp) => {
              this.cabecario = resp.data
            }).catch((err) => {})
      }
    },

    mudarPadrao(){
      let newl = ""
      if(this.modelo == 'padrao'){
        newl={
          brasao: '100',
          texto1: '100',
        }
      }else{
        newl={
          brasao: '30',
          texto1: '70',
        }
      }
      this.config = newl
    },

    modoEdicao(){
      this.confirme = 0;
      this.texto_logo = this.cabecario[0].texto_logo;

      if(this.modo == 1){
        this.modo = 2;
      }else{
        this.modo = 1;
      }
    },

    confirmar(){
      this.confirme = 1;
      sessionStorage.setItem("titulo_cb",this.titulo);
      sessionStorage.setItem("texto_logo_cb",this.texto_logo);
      sessionStorage.setItem("texto_segmento_cb",this.texto_segmento);
      this.modo = 1;
    },

    textoSegmento(){
      if(this.confirme == 0){
        axios.get('/relatorio-config-cabecario-txt/cabecario/'+this.cabecario_id)
              .then((resp) => {
                this.texto_cabecario = resp.data
              }).catch((err) => {});
      }
    },

    textoSegmentoAtual(txt){
      this.texto_segmento = txt;
    }

  }
});
</script>
