<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="col-md-12" style="margin-top: -15px; padding: 14px">
      <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Configuração de Relatórios</h2>
        </div>
        <div
          class="col-md-2"
          style="justify-content: right; display: flex; align-items: center"
        >
          <pm-Button
            :disabled="!situacaoAno"
            type="button"
            @click="visualizarConfig({}, 1)"
            label="CADASTRAR"
            class="p-button-success"
            icon="pi pi-external-link"
            title="CADASTRAR"
          ></pm-Button>
        </div>
      </div>
    </div>

    <div class="pm-card" style="padding-top: 0px">
      <div class="p-fluid formgrid grid">
        <div class="row">
          <div class="col-md-12">
            <pm-DataTable
              :value="configALL"
              dataKey="id"
              :rowHover="true"
              filterDisplay="menu"
              v-model:filters="filters"
              :globalFilterFields="['nome']"
              responsiveLayout="scroll"
            >
              <pm-Column field="ano" header="Ano"></pm-Column>

              <pm-Column field="logo" header="Brasão">
                <template #body="{ data }">
                  <p v-if="data.logo">
                    <img
                      :src="base_url + '/storage/' + data.logo"
                      style="width: 50%; height: 130px"
                    />
                  </p>
                  <p v-else style="color: #d4c709">
                    <b> sem imagem! </b>
                  </p>
                </template>
              </pm-Column>

              <pm-Column
                field="id"
                headerStyle="width: 4rem; text-align: center"
                bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
              >
                <template #body="{ data }">
                  <button
                    :disabled="!situacaoAno"
                    type="button"
                    @click="textoCabecario(data.id)"
                    icon="pi pi-check"
                    label="Texto do cabeçalho"
                    title=" Texto do cabeçalho"
                    class="btn btn-primary"
                    style="margin-left: 5px"
                  >
                    Textos Segmento
                  </button>

                  <button
                    :disabled="!situacaoAno"
                    type="button"
                    @click="visualizarConfig(data, 2)"
                    icon="pi pi-check"
                    label="Editar"
                    title="Editar"
                    class="btn btn-primary"
                    style="margin-left: 5px"
                  >
                    Editar
                  </button>
                </template>
              </pm-Column>
            </pm-DataTable>
          </div>

          <pm-Dialog
            :header="
              this.tipo == 1
                ? 'Adicionar cabeçalho aos Relatórios'
                : 'Editar cabeçalho dos Relatórios'
            "
            v-model:visible="showModal"
            :style="{ width: '90rem' }"
            :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
          >
            <div class="field col-12 md:col-12" style="padding-top: 0px">
              <div class="row grid">
                <div class="field col-12 md:col-12 lg:col-12" v-if="tipo == 2">
                  <div v-if="dados.templogo == ''">
                    <img
                      :src="base_url + '/storage/' + dados.logo"
                      style="
                        width: 100%;
                        height: 150px;
                        aspect-ratio: 3 / 2;
                        object-fit: contain;
                      "
                    />
                  </div>
                </div>

                <div class="field col-12 md:col-12 lg:col-12">
                  <label for="arquivo">Selecione o Logo:</label>
                  <va-file-upload
                    v-model="dados.templogo"
                    type="single"
                    file-types=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"
                  >
                  </va-file-upload>
                </div>

                <div class="field col-12 md:col-2 lg:col-2">
                  <label>Ano </label>
                  <input
                    type="number"
                    placeholder="Digite o Ano"
                    v-model="dados.ano"
                    class="p-inputtext"
                    style="width: 100%"
                    :disabled="tipo == 2"
                  />
                </div>
                <div class="row field col-12 md:col-10" style="padding: 25px;">
                  <div class="field-radiobutton" style="margin-top: 22px">
                    <label>Mostrar todos os alunos na ata?</label>&nbsp;&nbsp;
                    <pm-RadioButton v-model="dados.mostrar_todos_aluno_ata" name="sim" :value="1" />
                    <label>Sim</label>
                    <pm-RadioButton
                      v-model="dados.mostrar_todos_aluno_ata"
                      name="nao"
                      :value="0"
                      style="margin-left: 7px"
                    />
                    <label>Não</label>
                  </div>
                  <p>
                    &nbsp;&nbsp;<strong>*Caso marcado 'não', a ata irá mostrar apenas os alunos reprovados (não se aplica a alunos da creche).</strong>
                  </p>
                </div>

                <div class="field col-12 md:col-12 lg:col-12">
                  <span class="p-float-label">


                    <pm-Editor
                      v-model="dados.texto_logo"
                      editorStyle="height: 100px"
                      style="width: 100%"
                    >
                      <template v-slot:toolbar>
                        <span class="ql-formats">
                          <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                          <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                          <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                      </template>
                    </pm-Editor>


                  </span>
                </div>

                <div class="field col-12 md:col-12 lg:col-12">
                  <span class="p-float-label">


                    <pm-Editor
                      v-model="dados.titulo"
                      editorStyle="height: 100px"
                      style="width: 100%"
                    >
                      <template v-slot:toolbar>
                        <span class="ql-formats">
                          <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                          <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                          <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                      </template>
                    </pm-Editor>


                  </span>
                </div>


                <div
                  class="field col-12 md:col-12 lg:col-12"
                  v-if="this.tipo == 1"
                >
                  <button
                    @click="action(this.dados)"
                    style="float: right"
                    class="btn btn-primary"
                  >
                    Salvar
                  </button>
                </div>

                <div
                  class="field col-12 md:col-12 lg:col-12"
                  v-if="this.tipo == 2"
                >
                  <button
                    @click="action(this.dados)"
                    style="float: right"
                    class="btn btn-primary"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </pm-Dialog>

          <pm-Dialog
            header="Visualizar Cabeçário"
            v-model:visible="this.showVisualizar"
            :style="{ width: '90rem' }"
          >
            <div class="col-12 md:col-12">
              <visualizarCabecario
                :cabecario_id="this.cabecario_id"
                :local="0"
              />
            </div>
          </pm-Dialog>

          <pm-Dialog
            header="Cabeçalho Textos por Segmentos"
            v-model:visible="showModalCabecario"
            :style="{ width: '90rem' }"
            :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
          >
            <div
              class="col-12 md:col-12"
              style="padding-top: 0px; background-color: #f8f8f8; padding: 15px"
            >
              <div class="row grid">
                <div class="col-12 md:col-12 lg:col-12">
                  <button
                    @click="painelTextoCabecario({}, 1)"
                    style="float: right"
                    class="btn btn-success"
                  >
                    Cadastro
                  </button>
                </div>

                <div class="field col-12 md:col-12 lg:col-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Segmento</th>
                        <th>Texto</th>
                        <th>Integral</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="n in texto_cabecario" :key="n">
                        <td>{{ n.segmento_nome }}</td>
                        <td v-html="n.texto"></td>

                        <td>
                          <span
                            class="badge"
                            style="background-color: #24a366"
                            v-if="n.integral == 1"
                          >
                            sim
                          </span>
                          <span
                            class="badge"
                            style="background-color: #bc2120"
                            v-else
                          >
                            não
                          </span>
                        </td>
                        <td>
                          <button
                            @click="painelTextoCabecario(n, 2)"
                            class="btn btn-primary"
                            style="margin-right: 5px"
                          >
                            Editar
                          </button>
                          <button
                            @click="
                              this.showTextocabecarioDelete = true;
                              this.textocabecario.id = n.id;
                            "
                            class="btn btn-danger"
                          >
                            Deletar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </pm-Dialog>

          <pm-Dialog
            header="Deletar texto ?"
            v-model:visible="this.showTextocabecarioDelete"
            :style="{ width: '35rem' }"
          >
            <div class="col-12 md:col-12">
              <button
                @click="this.showTextocabecarioDelete = false"
                class="btn btn-danger"
                style="float: right"
              >
                Não
              </button>
              <button
                @click="painelTextoCabecario({}, 3)"
                class="btn btn-primary"
                style="margin-right: 5px; float: right"
              >
                Sim
              </button>
            </div>
          </pm-Dialog>

          <pm-Dialog
            :header="
              this.tipo_texto == 1
                ? 'Cadastrar Cabeçalho Segmento'
                : 'Editar Cabeçalho Segmento'
            "
            v-model:visible="showModalCabecarioCadastro"
            :style="{ width: '90rem' }"
            :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
          >
            <div class="field col-12 md:col-12" style="padding-top: 0px">
              <div class="row grid">
                <div class="field col-12 md:col-12 lg:col-12">
                  <label>Selecione a Segmento</label>
                  <select
                    class="p-inputtext p-component"
                    v-model="textocabecario.segmento_id"
                    style="
                      appearance: revert !important;
                      width: 100% !important;
                    "
                  >
                    <option value="0" disabled selected>
                      -- Escolha um Segmento --
                    </option>
                    <option v-for="n in segmentoALL" :key="n" :value="n.id">
                      {{ n.nome }}
                    </option>
                  </select>
                </div>
                <div class="field col-12 md:col-12 lg:col-12">
                  <span class="p-float-label">
                    <!--<pm-Textarea
                      v-model="textocabecario.texto"
                      rows="2"
                      cols="30"
                      style="width: 100%"
                    />
                    <label style="margin-top: -0.5rem !important"
                      >Texto Padrão</label
                    >-->

                    <pm-Editor
                      v-model="textocabecario.texto"
                      editorStyle="height: 100px"
                      style="width: 100%"
                    >
                      <template v-slot:toolbar>
                        <span class="ql-formats">
                          <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                          <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                          <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                      </template>
                    </pm-Editor>
                  </span>
                </div>
                <div class="field col-12 md:col-12 lg:col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="textocabecario.integral"
                      style="margin-top: 15px;"
                    />
                    <label class="form-check-label"><strong>Marque para usar em turmas de período INTEGRAL(DIURNA)</strong> </label>
                  </div>
                </div>
                <div
                  class="col-12 md:col-12 lg:col-12"
                  v-if="this.tipo_texto == 1"
                >
                  <button
                    @click="actionTextoCabecario(1)"
                    class="btn btn-primary"
                  >
                    Cadastro
                  </button>
                </div>
                <div
                  class="col-12 md:col-12 lg:col-12"
                  v-if="this.tipo_texto == 2"
                >
                  <button
                    @click="actionTextoCabecario(2)"
                    class="btn btn-primary"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </pm-Dialog>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import visualizarCabecario from "./components/configuracao/visualizarCabecario.vue";

export default defineComponent({
  components: {
    visualizarCabecario,
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/configuracao" },
      items_bread: [{ label: "Configuração de Relatorios" }],
      configALL: [],
      showModal: false,
      showModalCabecario: false,
      showModalCabecarioCadastro: false,
      showBrasao: false,
      showVisualizar: false,
      dados: {
        mostrar_todos_aluno_ata:'1'
      },
      tipo: 1,
      tipo_texto: 1,
      templogo: "",
      segmentoALL: [],
      segmento_id: 1,
      cabecario_id: 0,
      showTextocabecarioDelete: false,
      texto_cabecario: [],
      textocabecario: {
        id: 0,
        texto: "",
        segmento_id: 0,
        integral: false,
      },
      situacaoAno: 0
    };
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));

    this.getCabecarios();
    this.buscarSegmentoALL();
  },
  methods: {
    getCabecarios() {
      axios
        .get("/relatorio-config-cabecario")
        .then((resp) => {
          this.configALL = resp.data;
        })
        .catch((err) => {});
    },

    visualizar(data) {
      this.showVisualizar = true;
      this.cabecario_id = data.id;
    },

    visualizarConfig(data, tipo) {
      this.dados = {};
      this.dados = data;
      this.tipo = tipo;
      this.templogo = "";
      this.showModal = true;
      if (this.tipo == 1) {
        this.dados.mostrar_todos_aluno_ata = '0';
        this.dados.titulo = "ATA DE RESULTADO FINAIS";
        this.dados.texto_logo =
          "Prefeitura Municipal de São José de Ubá";
        this.dados.texto_cabecario =
          "Estabelecimento de Ensino: . Aos ... dias do mês de dezembro do ano de ..., encerrou-se o período letivo dos alunos abaixo relacionados no ano de escolaridade, da turma ..., com duzentos e cinco dias letivos e oitocentas e vinte horas de atividades neste Estabelecimento de Ensino, de acordo com as determinações emanadas na Resolução SED nº 02/13 de 01 de fevereiro de 2013, Resolução SED nº 0313 de 06 de maio de 2013, Resolução SED nº 0513 de 04 de outubro de 2013 e Resolução SEMED nº 005/2021 de 20 de abril de 2021 e das diretrizes estabelecidas pela LDBEN nº 9.394/96, com os seguintes resultados:";
      }
    },

    action(dados) {
      if (this.tipo == 1) {
        axios
          .post("/relatorio-config-cabecario", dados)
          .then((res) => {
            this.addImage(1, res.data.id);
            this.showModal = false;
            this.getCabecarios();
          })
          .catch((err) => {});
      } else {


        axios
          .put("/relatorio-config-cabecario/"+dados.id, dados)
          .then((res) => {
            if (typeof this.dados.templogo[0] !== "undefined") {
              this.addImage(2, res.data.id);
            }
            this.showModal = false;
            this.getCabecarios();
          })
          .catch((err) => {});
      }
    },

    async update(dados, id) {
      axios
        .put("/relatorio-config-cabecario/" + id, dados)
        .then((res) => {
          this.showModal = false;
          this.getCabecarios();
        })
        .catch((err) => {});
    },

    async addImage(tipo, id) {
      const formData = new FormData();
      formData.append("logo", this.dados.templogo[0]);

      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (tipo == 1) {
        axios
          .post(
            "/relatorio-config-cabecario/img/" + id + "/1",
            formData,
            config
          )
          .then((res) => {
            this.showModal = false;
            this.getCabecarios();
          })
          .catch((err) => {
            if (err.response.status === 406) {
              this.$vaToast.init({
                message: "Tamanho de imagem acima do permitido",
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                color: 'danger',
                fullWidth: false,
              });
            }else{
              this.$vaToast.init({
                message: "Ocorreu um erro inesperado",
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                color: 'danger',
                fullWidth: false,
              });
            }
          });
      } else {
        axios
          .post(
            "/relatorio-config-cabecario/img/" + id + "/2",
            formData,
            config
          )
          .then((res) => {
            this.showModal = false;
            this.getCabecarios();
          })
          .catch((err) => {
            if (err.response.status === 406) {
              this.$vaToast.init({
                message: "Tamanho de imagem acima do permitido",
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                color: 'danger',
                fullWidth: false,
              });
            }else{
              this.$vaToast.init({
                message: "Ocorreu um erro inesperado",
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                color: 'danger',
                fullWidth: false,
              });
            }
          });
      }
    },

    async buscarSegmentoALL() {
      const data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = data.data;
    },

    painelTextoCabecario(data, tipo) {
      console.log("QQQQQ", data, tipo);
      this.tipo_texto = tipo;
      if (tipo == 1) {
        this.showModalCabecarioCadastro = true;
      } else if (tipo == 2) {
        this.textocabecario = {
          id: data.id,
          texto: data.texto,
          segmento_id: data.segmento_id,
          integral: data.integral == 1 ? true : false,
        };
        this.showModalCabecarioCadastro = true;
      } else if (tipo == 3) {
        axios
          .delete("/relatorio-config-cabecario-txt/" + this.textocabecario.id)
          .then((resp) => {
            this.textocabecario.id = 0;
          })
          .catch((err) => {
            console.log(err.response.data);
          });

        this.showTextocabecarioDelete = false;
        this.textoCabecario(this.cabecario_id);
      }
    },

    textoCabecario(cabecario_id) {
      this.cabecario_id = 0;
      this.cabecario_id = cabecario_id;

      axios
        .get("/relatorio-config-cabecario-txt/cabecario/" + this.cabecario_id)
        .then((resp) => {
          this.texto_cabecario = resp.data;
        })
        .catch((err) => {});

      console.log(this.texto_cabecario);
      this.showModalCabecario = true;
    },

    textoCabecarioPainelCadastro() {
      this.showModalCabecarioCadastro = true;
    },

    actionTextoCabecario(tipo) {
      if (tipo == 1) {
        axios
          .post("/relatorio-config-cabecario-txt", {
            cabecario_id: this.cabecario_id,
            segmento_id: this.textocabecario.segmento_id,
            texto: this.textocabecario.texto,
            integral: this.textocabecario.integral,
          })
          .then((resp) => {
            this.textoCabecario(this.cabecario_id);
          })
          .catch((err) => {});
      } else {
        axios
          .put("/relatorio-config-cabecario-txt/" + this.textocabecario.id, {
            cabecario_id: this.cabecario_id,
            segmento_id: this.textocabecario.segmento_id,
            texto: this.textocabecario.texto,
            integral: this.textocabecario.integral,
          })
          .then((resp) => {
            this.textoCabecario(this.cabecario_id);
          })
          .catch((err) => {});
      }

      this.textocabecario.texto = "";
      this.textocabecario.id = 0;
      this.textocabecario.segmento_id = 0;
      this.textocabecario.integral = 0;
      this.showModalCabecarioCadastro = false;
    },
  },
});
</script>

<style></style>
