<template>
  <div style="text-align: center;">
    <div v-html="texto_cabecario.texto" v-if="this.editar == 0"></div>

    <textarea  rows="5" cols="30" v-model="this.texto" @change="textoSegmento()" class="p-inputtext" style="width: 100%;" v-if="this.editar == 1"></textarea>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';

export default defineComponent ({
  props:{
    cabecario_id:0,
    segmento_id:'',
    integral:'',
    editar:''
  },
  data(){
    return{
      texto_cabecario:"",
      contentEditable:false,
      texto: ""
    }
  },
  async beforeMount() {
    await this.segmentoTexto();

    if(this.editar == 1){
      this.contentEditable = true
    }else{
      this.contentEditable = false
    }
  },
  methods: {
    segmentoTexto(){
      axios.get('/relatorio-config-cabecario-txt/por-segmento/'+this.cabecario_id+'/'+this.segmento_id+'/'+this.integral)
          .then((resp) => {
            this.texto_cabecario = resp.data
            this.texto = this.texto_cabecario.texto
          }).catch((err) => {});

    },
    textoSegmento(){
      this.$emit('textoSegmentoAtual', this.texto);
    }
  }
});
</script>
